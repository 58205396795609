// Load Bootstrap JS
import bootstrap from 'bootstrap'

// Load Styles
import '../scss/main.scss';

// App code
console.log(`Hello ${process.env.HELLO}`);

$( ".search-btn" ).click(function() {
    $( ".search-form" ).toggle();
  });

